const PassiveToolChartConfig = {
  customs: {
    legends: [
      {
        color: "#000000",
        title: "Active Sentence",
      },
      {
        color: "#0433ff",
        title: "Passive Sentence",
      },
    ],
  },
  padding: {
    top: 5,
  },
  size: {
    height: 400,
  },
  passiveSentences: [],
  data: {
    json: [],
    color: function (color, d) {
      return PassiveToolChartConfig.passiveSentences.includes(d.index)
        ? "#0433ff"
        : "#000000";
    },
    keys: {
      value: ["count"],
    },
    onclick: null,
    types: {
      count: "bar",
    },
  },
  grid: {
    hidden: false,
    y: {
      lines: [],
    },
  },
  legend: {
    hide: true,
  },
  tooltip: {
    show: false,
  },
  axis: {
    x: {
      label: {
        text: "Sentences",
        position: "outer-center",
      },
    },
    y: {
      tick: {
        outer: true,
      },
      label: {
        text: "Number of Words",
        position: "outer-middle",
      },
    },
  },
};

export default PassiveToolChartConfig;
