<template>
  <div class="container">
    <template v-if="intakeStage">
      <div class="relative w-full h-full mb-4">
        <textarea
          ref="rawInputArea"
          v-model="raw"
          placeholder="Paste text to analyze here - no more than 28 sentences, but more than one."
          class="w-full h-full border text-lg text-gray-800 border-gray-300 rounded-lg p-6"
          :class="{
            'focus:border-red-700 focus:ring-red-700': !inputIsEmpty && !valid,
          }"
        />
        <div class="absolute -bottom-8 text-red-700 w-full text-sm font-bold">
          <span v-if="inputFocused && tooLong"
            >Pasted text is too long. Max: 28 sentences. You have:
            {{ sentences.length }}.</span
          >
          <span v-if="inputFocused && !inputIsEmpty && tooShort"
            >Pasted text is too short (must be more than one sentence)</span
          >
        </div>
      </div>
      <div class="py-10 flex items-center w-full">
        <Button @click="start" :disabled="!valid" class="w-full">
          <PlayIcon />
          <span class="ml-2">Start Assessment</span>
        </Button>
      </div>
    </template>
    <Assessment v-else-if="assessmentStage" :sentences="sentences" />
  </div>
</template>

<script>
import { getSentences } from "../lib/parsing.js";

import Button from "./Button.vue";
import PlayIcon from "./PlayIcon.vue";
import Assessment from "./Assessment.vue";

export default {
  name: "SentenceInference",

  components: {
    Button,
    PlayIcon,
    Assessment,
  },

  data() {
    return {
      raw: "",
      inputFocused: false,
      stage: 1,
      selectedTab: 0,
    };
  },

  computed: {
    inputIsEmpty() {
      return !this.raw;
    },
    sentences() {
      return getSentences(this.raw);
    },
    tooLong() {
      return this.sentences.length > 28;
    },
    tooShort() {
      return this.sentences.length < 2;
    },
    valid() {
      return !this.tooShort && !this.tooLong;
    },
    intakeStage() {
      return this.stage == 1;
    },
    assessmentStage() {
      return this.stage == 2;
    },
    resultData() {
      let result;
      try {
        result = JSON.parse(this.raw);
      } catch (e) {
        // NOOP
      }

      return result;
    },
  },

  methods: {
    start() {
      if (!this.valid || this.inputIsEmpty) {
        this.$refs.rawInputArea.focus();
        return;
      }

      this.stage++;
    },
    changeTab(index) {
      this.selectedTab = index;
    },
  },

  mounted() {
    this.$refs.rawInputArea.addEventListener("focus", () => {
      this.inputFocused = true;
    });

    this.$refs.rawInputArea.addEventListener("blur", () => {
      this.inputFocused = false;
    });
  },
};
</script>
