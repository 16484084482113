<template>
  <nav class="container mb-3">
    <ul class="flex justify-end">
      <slot />
    </ul>
  </nav>
</template>

<script>
export default {
  name: "Navbar",
};
</script>
