<template>
  <li>
    <a
      class="py-4 px-6 block hover:text-indigo-500 focus:outline-none"
      :class="{ 'active-tab': selected }"
      @click="$emit('click', $event)"
      href="javascript:void(0)"
      ><slot
    /></a>
  </li>
</template>
<style>
.active-tab {
  @apply text-indigo-500 border-b-2 font-medium border-indigo-500;
}
</style>
<script>
export default {
  name: "NavLink",
  props: {
    selected: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
