<template>
  <div>
    <span
      v-for="(sentence, index) in sentences"
      :key="index"
      :style="reactionInlineStyle(reactions[index])"
      >{{ sentence }}&nbsp;</span
    >
  </div>
</template>

<script>
import { reactionToInlineStyle } from "../lib/parsing.js";
export default {
  name: "Result",

  props: {
    sentences: {
      type: Array,
      required: true,
    },
    reactions: {
      type: Array,
      required: true,
    },
  },

  methods: {
    asHtml() {
      return this.$el.innerHTML;
    },
    reactionInlineStyle(reaction) {
      return reactionToInlineStyle(reaction);
    },
  },
};
</script>
