<template>
  <button
    @click="$emit('click', $event)"
    type="button"
    class="inline-flex items-center justify-center px-3 py-2 border border-transparent shadow-sm text-sm leading-4 font-medium rounded-md text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
    :class="{
      'bg-indigo-600 hover:bg-indigo-700': !disabled,
      'bg-gray-400 opacity-30 cursor-not-allowed ': disabled,
    }"
  >
    <slot />
  </button>
</template>

<script>
export default {
  name: "Button",

  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
