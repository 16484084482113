import nlp from "compromise";
import nlpSentences from "compromise-sentences";

nlp.extend(nlpSentences);

export function getSentences(text) {
  return nlp(text).sentences().out("array");
}

export function isSentencePassive(sentence) {
  return (
    nlp(sentence)
      .match("(was|are|is|were) (be|being)? #Adverb? #PastTense #Adverb? by? ")
      .out("array").length > 0
  );
}

export function previewSentence(text) {
  return [
    ...nlp(text).before("#Verb").out("array"),
    nlp(text).match("#Verb").first().text(),
  ].join(" ");
}

export function reactionToInlineStyle(reaction) {
  switch (reaction) {
    case "clear": {
      return { color: "#059669" };
    }
    case "unclear": {
      return { color: "#d97706" };
    }
    case "misleading": {
      return { color: "#dc2626" };
    }
  }
}
