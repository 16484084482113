<template>
  <div :id="identifier" class="mb-5 py-5 px-3" :class="classNames">
    <vue-c3 :handler="handler"></vue-c3>
    <div v-if="legends.length > 0" class="c3-legends flex justify-center">
      <div class="px-4" v-for="(legend, index) in legends" :key="index">
        <span
          :style="{ backgroundColor: `${legend.color}` }"
          class="legend-color bg-blue-700"
        >
        </span>
        {{ legend.title }}
      </div>
    </div>
  </div>
</template>
<style>
.c3-legends {
  margin-top: -20px;
  font-size: 1rem;
  font-weight: 500;
}
.legend-color {
  display: inline-block;
  width: 12px;
  height: 12px;
  margin-right: 3px;
}
</style>
<script>
import Vue from "vue";
import VueC3 from "vue-c3";
export default {
  name: "C3Chart",
  components: {
    VueC3,
  },
  mounted() {
    // to init the graph call:
    this.handler.$emit("init", this.options);
  },
  data() {
    return {
      handler: new Vue(),
    };
  },
  props: {
    options: {
      type: Object,
      default: () => {},
    },
    identifier: {
      type: String,
      default: "C3-Chart",
    },
    legends: {
      type: Array,
      default: () => [],
    },
    classNames: {
      type: String,
      default: "",
    },
  },
};
</script>
