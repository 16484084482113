<template>
  <div class="container">
    <template v-if="intakeStage">
      <div class="relative w-full h-full mb-4">
        <textarea
          ref="rawInputArea"
          v-model="raw"
          placeholder="Paste text to analyze here - no more than 28 sentences, but more than one."
          class="w-full h-full border text-lg text-gray-800 border-gray-300 rounded-lg p-6"
          :class="{
            'focus:border-red-700 focus:ring-red-700': !inputIsEmpty && !valid,
          }"
        />
        <div class="absolute -bottom-8 text-red-700 w-full text-sm font-bold">
          <span v-if="inputFocused && tooLong"
            >Pasted text is too long. Max: 28 sentences. You have:
            {{ sentences.length }}.</span
          >
          <span v-if="inputFocused && !inputIsEmpty && tooShort"
            >Pasted text is too short (must be more than one sentence)</span
          >
        </div>
      </div>
      <div class="py-10 flex items-center w-full">
        <Button
          ref="downloadButton"
          @click="start"
          :disabled="!valid"
          class="w-full"
        >
          <PlayIcon />
          <span class="ml-2">Start Analysis</span>
        </Button>
      </div>
    </template>
    <template v-if="assessmentStage">
      <div class="flex items-center mb-3 ml-10">
        <Button :disabled="downloading" @click="setChartDownloadButton">
          Download Chart
        </Button>
        <Button @click="quitPassiveTool" class="ml-3">
          Quit Passive Tool
        </Button>
      </div>
      <C3Chart :options="chartOptions" :legends="legends" />
      <p class="px-3">
        <span
          class="transition-all duration-500 ease-in-out p-1"
          :class="index === clickedSentence ? 'bg-yellow-400' : ''"
          v-for="(sentence, index) in sentences"
          :key="index"
        >
          {{ sentence }}
        </span>
      </p>
      <div class="py-10 px-3 flex items-center">
        <Button @click="tryAgain" :disabled="false" class="w-full">
          <PlayIcon :classNames="'transform rotate-180'" />
          <span class="ml-2">Try Again</span>
        </Button>
      </div>
    </template>
  </div>
</template>
<style>
.c3-axis-x .tick {
  display: none;
}
.c3 .c3-grid text {
  fill: #856404;
}
.c3 .c3-grid line {
  stroke: #856404;
  stroke-width: 2;
  opacity: 1;
}
</style>
<script>
import { getSentences, isSentencePassive } from "../lib/parsing.js";

import Button from "./Button.vue";
import PlayIcon from "./PlayIcon.vue";
import C3Chart from "./C3Chart";
import PassiveToolChartConfig from "./../chartsConfigs/PassiveToolChartConfig";
import html2canvas from "html2canvas";

export default {
  name: "PassiveTool",

  components: {
    C3Chart,
    Button,
    PlayIcon,
  },

  data() {
    return {
      raw: "",
      inputFocused: false,
      stage: 1,
      selectedTab: 0,
      clickedSentence: null,
      legends: PassiveToolChartConfig.customs.legends,
      downloading: false,
    };
  },
  props: {
    quitPassiveTool: {
      type: Function,
    },
  },
  computed: {
    inputIsEmpty() {
      return !this.raw;
    },
    sentences() {
      return getSentences(this.raw);
    },
    tooLong() {
      return this.sentences.length > 28;
    },
    tooShort() {
      return this.sentences.length < 2;
    },
    valid() {
      return !this.tooShort && !this.tooLong;
    },
    chartOptions() {
      let totalWords = 0;
      PassiveToolChartConfig.data.json = this.sentences.map(
        (sentence, index) => {
          const num_words = sentence.split(" ").length;
          totalWords += num_words;
          return {
            index: index,
            count: num_words,
            isPassive: this.isPassive(sentence),
          };
        }
      );

      const average = Math.round(totalWords / (this.sentences.length ?? 1));

      PassiveToolChartConfig.passiveSentences = PassiveToolChartConfig.data.json.map(
        (data, index) => {
          if (data.isPassive) return index;
        }
      );
      PassiveToolChartConfig.data.onclick = this.handleChartClick;
      PassiveToolChartConfig.data.onmouseover = this.handleChartClick;
      PassiveToolChartConfig.grid.y.lines = [
        { value: average, text: `Average words (${average})` },
      ];
      return PassiveToolChartConfig;
    },
    intakeStage() {
      return this.stage == 1;
    },
    assessmentStage() {
      return this.stage == 2;
    },
  },

  methods: {
    start() {
      if (!this.valid || this.inputIsEmpty) {
        this.$refs.rawInputArea.focus();
        return;
      }
      this.stage++;
    },
    tryAgain() {
      this.stage--;
    },
    isPassive(sentence) {
      return isSentencePassive(sentence);
    },
    changeTab(index) {
      this.selectedTab = index;
    },
    // eslint-disable-next-line no-unused-vars
    handleChartClick(d, element) {
      this.clickedSentence = d.index;
    },

    setChartDownloadButton() {
      this.downloading = true;
      html2canvas(document.querySelector("#C3-Chart"))
        .then((canvas) => {
          let imageData = canvas.toDataURL("image/png");
          let link = document.createElement("a");
          link.download = "download_chart.png";
          link.href = imageData;
          link.click();
        })
        .finally(() => (this.downloading = false));
    },
  },

  mounted() {
    this.$refs.rawInputArea.addEventListener("focus", () => {
      this.inputFocused = true;
    });

    this.$refs.rawInputArea.addEventListener("blur", () => {
      this.inputFocused = false;
    });
  },
};
</script>
