<template>
  <div class="w-full flex-1 flex flex-col">
    <div class="flex-1 w-full bg-gray-50 text-lg text-gray-800 rounded-lg p-6">
      <template v-if="!isFinished">
        <span
          class="text-gray-300"
          :style="reactionInlineStyle(reactions[index])"
          v-for="(sentence, index) in previousSentences"
          :key="index"
          >{{ sentence }}&nbsp;</span
        >
        <span class="text-gray-700">{{ currentSentence }}&nbsp;</span>
        <span
          v-if="previewNextOnly && !isLastSentence"
          class="text-gray-900 font-semibold"
          >{{ previewSentence(nextSentence) }}</span
        >
        <span v-else class="text-gray-900 font-semibold">{{
          nextSentence
        }}</span>
      </template>
      <template>
        <Result
          ref="result"
          :style="{ display: !isFinished ? 'none' : 'block' }"
          :sentences="sentences"
          :reactions="reactions"
        />
      </template>
    </div>
    <div
      v-if="!isLastSentence"
      class="pt-10 flex items-center w-full space-x-6"
    >
      <template v-if="previewNextOnly">
        <p>Can you predict what is coming next?</p>
        <Button @click="revealPreview">Yes</Button>
        <Button @click="nextStep('unclear')">No</Button>
      </template>
      <template v-else>
        <p>Was your guess correct?</p>
        <Button @click="nextStep('clear')">Yes</Button>
        <Button @click="nextStep('misleading')">No</Button>
      </template>
    </div>
    <div class="my-10">
      <p v-if="copied" class="text-indigo-600">Copied results!</p>
      <button v-else class="text-indigo-600" @click="richTextCopy">
        Copy results to clipboard
      </button>
    </div>
  </div>
</template>

<script>
import { reactionToInlineStyle } from "../lib/parsing.js";
import { previewSentence } from "../lib/parsing.js";

import Button from "./Button.vue";
import Result from "./Result.vue";

export default {
  name: "Assessment",

  components: {
    Button,
    Result,
  },

  props: {
    sentences: {
      type: Array,
      required: true,
    },
  },

  computed: {
    previousSentences() {
      return this.sentences.slice(0, this.currentSentenceIndex);
    },
    currentSentence() {
      return this.sentences[this.currentSentenceIndex];
    },
    nextSentence() {
      return this.sentences[this.currentSentenceIndex + 1];
    },
    isLastSentence() {
      return this.nextSentence === undefined;
    },
    isFinished() {
      return this.reactions.length === this.sentences.length - 1;
    },
    result() {
      return JSON.stringify({
        sentences: this.sentences,
        reactions: this.reactions,
      });
    },
  },

  data() {
    return {
      reactions: [],
      currentSentenceIndex: 0,
      previewNextOnly: true,
      showResults: false,
      copied: false,
    };
  },

  methods: {
    previewSentence,
    revealPreview() {
      this.previewNextOnly = false;
    },
    nextStep(reaction) {
      this.reactions.push(reaction);
      this.currentSentenceIndex++;
      this.previewNextOnly = true;
    },
    richTextCopy() {
      this.copied = true;

      setTimeout(() => {
        this.copied = false;
      }, 2000);

      // Get result as HTML
      const html = this.$refs.result.asHtml();

      function copyListener(e) {
        e.clipboardData.setData("text/html", html);
        e.clipboardData.setData("text/plain", html);
        e.preventDefault();
      }
      document.addEventListener("copy", copyListener);
      document.execCommand("copy");
      document.removeEventListener("copy", copyListener);
    },
    reactionInlineStyle(reaction) {
      return reactionToInlineStyle(reaction);
    },
  },
};
</script>
