<template>
  <div class="m-auto max-w-7xl">
    <div class="h-screen px-6 flex flex-col items-center">
      <header class="py-10 self-start">
        <h2 class="text-5xl text">Fluiditext</h2>
        <h1 class="text-xs font-bold text-indigo-600 uppercase">
          The text fluidity analyzer
        </h1>
      </header>
      <Navbar>
        <NavLink @click="changeTab(0)" :selected="selectedTab === 0">
          Sentence Inference
        </NavLink>
        <NavLink @click="changeTab(1)" :selected="selectedTab === 1">
          Passive/Active Tool
        </NavLink>
      </Navbar>
      <SentenceInference v-if="selectedTab === 0" />

      <PassiveTool
        :quitPassiveTool="() => changeTab(0)"
        v-else-if="selectedTab === 1"
      />
    </div>
  </div>
</template>

<script>
import { getSentences } from "./lib/parsing.js";

import Navbar from "./components/utilities/Navbar.vue";
import NavLink from "./components/utilities/NavLink";
import SentenceInference from "./components/SentenceInference";
import PassiveTool from "./components/PassiveTool";

export default {
  name: "App",

  components: {
    PassiveTool,
    SentenceInference,
    NavLink,
    Navbar,
  },

  data() {
    return {
      raw: "",
      inputFocused: false,
      stage: 1,
      selectedTab: 0,
    };
  },

  computed: {
    inputIsEmpty() {
      return !this.raw;
    },
    sentences() {
      return getSentences(this.raw);
    },
    tooLong() {
      return this.sentences.length > 15;
    },
    tooShort() {
      return this.sentences.length < 2;
    },
    valid() {
      return !this.tooShort && !this.tooLong;
    },
    intakeStage() {
      return this.stage == 1;
    },
    assessmentStage() {
      return this.stage == 2;
    },
    resultData() {
      let result;

      try {
        result = JSON.parse(this.raw);
      } catch (e) {
        // NOOP
      }

      return result;
    },
  },

  methods: {
    start() {
      if (!this.valid || this.inputIsEmpty) {
        this.$refs.rawInputArea.focus();
        return;
      }

      this.stage++;
    },
    changeTab(index) {
      this.selectedTab = index;
    },
  },

  mounted() {},
};
</script>
